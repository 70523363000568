import React, { useContext, useEffect, useRef, useState } from 'react';
import { appContext } from "../../App";
import AppConfig from "../../app-config";
import _ from "lodash";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { dashboardAddPropertyChannelById, dashboardDeletePropertyChannelById } from '../../store';

const Channels = (props) => {
    let dispatch = useDispatch();
    // http service from context
    const { http } = useContext(appContext);
    const channelPageRef = useRef();
    const leadChannelRef = useRef();
    const addNewIlsRef = useRef();
    const leadChannelDelRef = useRef();
    const pickUpTimes = useRef();
    const [channelsList, setChannelsList] = useState([]);
    const [leadChannel, setLeadChannel] = useState();
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [listingLink, setListingLink] = useState('');
    const [branchId, setBranchId] = useState('');
    const [networkId, setNetworkId] = useState('');
    const [order, setOrder] = useState('asc');
    const [sortBy, setSortBy] = useState('name');
    const [unAssignedLeadChannels, setUnAssignedLeadChannels] = useState(props.totalLeadChannels.unAssignedLeadChannels);
    const [isSaved, setIsSaved] = useState(false);
    const [message,setMessage] = useState("");
    const [newIlsSelected,setNewIlsSelected] = useState(false);
    const [newIlsLeadChannelMap, setNewIlsLeadChannelMap] = useState([]);
    const filterNewIlsRef = useRef();
    const [channelsUpdated, setChannelsUpdated] = useState(false);
    const [pickUpTimesRes,setPickUpTimesRes] = useState([]);
    
    const getPage = (lists, from, to) => {
        lists =  _.orderBy(lists,[list=>list[sortBy].toLowerCase()],order)
        return lists.slice(from - 1, to);
    };
    
    useEffect(() => {
        updateChannelList();
        setUnAssignedLeadChannels(props.totalLeadChannels.unAssignedLeadChannels);
    }, [props]);

    useEffect(()=>{
        console.log("data loaded");
    },[unAssignedLeadChannels])

    useEffect(() => {
        newIlsLeadChannelMap.length > 0 ? setNewIlsSelected(false) : setNewIlsSelected(true);
    }, [newIlsLeadChannelMap]);

    
    useEffect(() => {
        if(channelPageRef && channelPageRef.current) {
            let startRow = ((channelPageRef.current.currentPage -1) * channelPageRef.current.rowsPerPage ) + 1;
            let endRow = channelPageRef.current.rowsPerPage * channelPageRef.current.currentPage > props.channelList.length ? props.channelList.length : channelPageRef.current.rowsPerPage * channelPageRef.current.currentPage;
            setChannelsList(getPage(props.channelList, startRow, endRow));
        }
    }, [order,sortBy]);

   
    const updateChannelList = () => {
        setChannelsList([]);
        if(props.channelList) {
            setChannelsList(getPage(props.channelList, 1, 10))      
        } 
        
        // pagination
        if(channelPageRef && channelPageRef.current) {
            channelPageRef.current.rowsPerPage = "10";
            channelPageRef.current.currentPage = "1";
            channelPageRef.current.addEventListener('pagingChange', (e) => {
                setChannelsList(getPage(props.channelList, e.detail.startRow, e.detail.endRow));
            });
        }
    }

    const handleChange = (event, type) => {
        switch(type) {
            case 'emailAddress':
                return setEmailAddress(event.target.value);
            case 'phoneNumber':
                return setPhoneNumber(event.target.value);
            case 'listingLink':
                return setListingLink(event.target.value);
            case 'branchId':
                return setBranchId(event.target.value);
            case 'networkId':
                return setNetworkId(event.target.value);
            default:
                return '';
        }
    }

    const editChannel = (leadchannel) => {
        setMessage("");
        setLeadChannel(leadchannel);
        setEmailAddress(leadchannel.emailAddress);
        setBranchId(leadchannel.branchId);
        setNetworkId(leadchannel.networkId);
        setPhoneNumber(leadchannel.phoneNumber);
        setListingLink(leadchannel.listingLink);
        leadChannelRef.current.open();
    };

    const deleteChannel = (leadchannel) => {
        setMessage("");
        setLeadChannel(leadchannel);
        leadChannelDelRef.current.open();
    };

    const cancelDelete = () => {
        leadChannelDelRef.current.close();
    };

    const confirmDelete = () => {
        deleteConfirmation(leadChannel);
        leadChannelDelRef.current.close();
    };

    const cancelEdit = () => {
        leadChannelRef.current.close();
    };

    const saveEdit = () => {
        leadChannel.emailAddress = emailAddress;
        leadChannel.phoneNumber = phoneNumber;
        leadChannel.listingLink = listingLink;
        leadChannel.branchId = branchId;
        leadChannel.networkId = networkId;
        let modifiedLeadChannel = _.cloneDeep(leadChannel);
        editLeadChannel(modifiedLeadChannel);  
        setChannelsUpdated(true);
    };
    

    const deleteConfirmation = (leadChannel) =>{
        var url =  AppConfig.uri.deleteLeadChannel.replace('{lid}',leadChannel.id).replace('{id}',props.property.propertyId);
        http.callApi('DELETE', url)
        .then((response) => {
            let data = {
                propertyId : props.property.propertyId,
                leadChannelId : leadChannel.id
            }
            dispatch(dashboardDeletePropertyChannelById(data));
            setUnAssignedLeadChannels(response.unAssignedLeadChannels);
            setChannelsUpdated(true);
            props.channelList.splice(0, props.channelList.length, ...response.assignedLeadChannels);
            updateChannelList(); 
        }).catch((message) => {
            console.log('Error occurred while deleting channel. '+message); 
        });
    }

    const editLeadChannel = (leadChannel) =>{
        var url =  AppConfig.uri.updateLeadChannel.replace('{id}',props.property.propertyId);
        http.callApi('PUT', url, leadChannel)
        .then((response) => {
            setUnAssignedLeadChannels(response.unAssignedLeadChannels);
            props.channelList.splice(0, props.channelList.length, ...response.assignedLeadChannels);
            updateChannelList();
            leadChannelRef.current.close();
        }).catch((message) => {
            console.log('Error occurred while edit channel. '+message); 
        });
    }
      
    const channelSort = (field) => {
        if(field == sortBy) {
            setOrder(order == 'asc' ?'desc':'asc');
        } else {
            setSortBy(field);
            setOrder('asc');
        }
    }
       
    const addNewIls = () => {
        setMessage("");
        if(channelsUpdated){
            setUnAssignedLeadChannels(unAssignedLeadChannels);
             }else{
                setUnAssignedLeadChannels(props.totalLeadChannels.unAssignedLeadChannels);
        }
        setChannelsUpdated(false);
        addNewIlsRef.current.open();
        filterNewIlsRef.current.value = "";
        setNewIlsSelected(true);
        setIsSaved(false);
        setMessage("");
        setNewIlsLeadChannelMap([]);
        document.querySelectorAll('input[name=ilsCheckBox]').forEach(el => el.checked = false);
    };
    const cancelAddNewIls = () => {
        addNewIlsRef.current.close();
    };
    const addNewIlsChannelCheck = (event, ilsLeadChannel) => {
        var data = {};
        data.id = ilsLeadChannel.id;
        data.name = ilsLeadChannel.name;
        if(event.target.checked){
            if(newIlsLeadChannelMap && newIlsLeadChannelMap.length>0){
                setNewIlsLeadChannelMap([...newIlsLeadChannelMap, data]);
            }else{
                setNewIlsLeadChannelMap([data]);
            }
        } else{
            let leadChannels = _.remove(newIlsLeadChannelMap, function (lc) {
                return lc.id != ilsLeadChannel.id;
            });
            setNewIlsLeadChannelMap(leadChannels);
        }
    }
    
    const saveNewIls = () => {
        var url =  AppConfig.uri.updateLeadChannel.replace('{id}',props.property.propertyId);
        http.callApi('POST', url, newIlsLeadChannelMap)
        .then((response) => {
            setUnAssignedLeadChannels(response.unAssignedLeadChannels);
            props.channelList.splice(0, props.channelList.length, ...response.assignedLeadChannels);
            updateChannelList(); 
            setChannelsUpdated(true);
            setIsSaved(true);
            setMessage("New syndication channel added successfully.");
            let data = {
                propertyId : props.property.propertyId,
                channels : newIlsLeadChannelMap
            };
            dispatch(dashboardAddPropertyChannelById(data));
            addNewIlsRef.current.close();
        }).catch((error) => {
            setIsSaved(true);
            setMessage("Error while adding the new syndication channel. Please contact support@realpage.com for further assistance.");
            addNewIlsRef.current.close();
        });
    };

    const filterIlsChannelChange = (e) => {
        const filteredData  = props.totalLeadChannels.unAssignedLeadChannels.filter((match) => match.name.toLowerCase().includes(e.target.value.toLowerCase()));
        setUnAssignedLeadChannels(filteredData);
    };

    const filterAssignedIlsChannelChange = (e) => {
        let filteredData  = props.totalLeadChannels.assignedLeadChannels.filter((match) => match.name.toLowerCase().includes(e.target.value.toLowerCase()));
        filteredData =  _.orderBy(filteredData,[filteredData=>filteredData['name'].toLowerCase()],'asc'); 
        let startRow = ((channelPageRef.current.currentPage -1) * channelPageRef.current.rowsPerPage ) + 1;
        let endRow = channelPageRef.current.rowsPerPage * channelPageRef.current.currentPage > filteredData.length ? filteredData.length : channelPageRef.current.rowsPerPage * channelPageRef.current.currentPage;
        setChannelsList(getPage(filteredData, startRow, endRow));
    };

    const pickupTimes = (leadChannel) => {
        setLeadChannel(leadChannel);
        var url =  AppConfig.uri.pickUpTimes.replace('{channelId}',leadChannel.id);
        http.callApi('GET', url)
        .then((response) => {
            preparePickUpTimesObj(response);
            pickUpTimes.current.open();
        }).catch((message) => {
            console.log('Error occurred while pick-up time. '+message); 
        });
    };

    const openListingLink = (url) => {
        if (!url) {
            return;
          }
          if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
            url = 'http://' + url;
          }
        window.open(url,'_blank')
    }

    const cancelpickUpTimes = () => {
        pickUpTimes.current.close();
    }

    const preparePickUpTimesObj = (data) => {
        let testObj = [];
        let timeobj = {
            "_2am": '2:00am',
            "_2pm": '2:00pm',
            "_4am": '4:00am',
            "_4pm": '4:00pm',
            "_6am": '6:00am',
            "_6pm": '6:00pm',
            "_8am": '8:00am',
            "_8pm": '8:00pm',
            "_10am": '10:00am',
            "_10pm": '10:00pm',
            "_12am": '12:00am',
            "_12pm": '12:00pm'
        };
        let pickUpTimeObj ={};
        let obj = data.pickupTimes? JSON.parse(data.pickupTimes) : null;
        if(obj){
            for (let name in obj) {
                if(obj[name] == true){
                    testObj.push(timeobj[name]);
                }
            }
            pickUpTimeObj.subChannelDetails = data.subChannelDetails;
            pickUpTimeObj.pickupTimes = testObj? testObj.sort((a,b)=> moment(a,"h:mma").diff(moment(b,"h:mma"))) :null;
            let time = new Date().toLocaleString([], { hour: '2-digit', minute: '2-digit' });
            for (var i = 0; i < pickUpTimeObj.pickupTimes.length; i++) {
                if(moment(time, 'h:mma').isBefore(moment(pickUpTimeObj.pickupTimes[i], 'h:mma'))){
                    pickUpTimeObj.nextPickUpTime = pickUpTimeObj.pickupTimes[i];
                    break;
                }
            }
            if(!pickUpTimeObj.nextPickUpTime){
                pickUpTimeObj.nextPickUpTime = pickUpTimeObj.pickupTimes[0];
            }
        }
        setPickUpTimesRes(pickUpTimeObj);
    } 
   
    useEffect(() => {
        setMessage("");
    }, [props.isFromCompanyTab]);

    return (
        <>
            <div class="content-text">
                <p class="r-text-lg r-font-bold r-mt-12 r-mb-3">Active ILS Channels
                    <raul-tooltip class="r-text r-cursor-pointer" text="<p>An active ILS configuration will create a file of the property's standard marketing 
                        data and deliver it to the ILS regularly. </p><p>The ILS will pick up the pieces of data from the file that they use to display
                        information about the property.</p>" placement="top">
                        <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                    </raul-tooltip>
                </p>
            </div>
            {
            isSaved && message.length > 0 && <div class="r-mt-3 r-pb-3">
            <raul-alert
              heading=""
              content={message}
              rounded
              variant={message.includes("Error") ? "danger" : "success"}
            ></raul-alert>
            </div>
          }
            <raul-input type="search" style={{ width: '100%' }}>
                <input type="text" placeholder="Search"  onChange={filterAssignedIlsChannelChange} />
                <div>
                    {
                        unAssignedLeadChannels.length > 0 ?
                        <raul-button variant="primary" class="r-top-0 r-right-0 r-ml-6" onClick={() => addNewIls()}>
                            <raul-icon icon="add" kind="consumer" class="r-icon-md" ></raul-icon>Add New ILS
                        </raul-button> 
                        :
                        <raul-tooltip class="r-text r-cursor-pointer" text="All the ILSs have been added to this property. No new ILS to add." placement="top">   
                            <raul-button variant="primary" class="r-top-0 r-right-0 r-ml-6" disabled={true}>
                                <raul-icon icon="add" kind="consumer" class="r-icon-md" ></raul-icon>Add New ILS
                            </raul-button>
                        </raul-tooltip>
                    }
                </div>
            </raul-input>
            <>
            {
                channelsList && channelsList?.length == 0 ?
                <p class="r-text-center r-pt-24 r-pb-24">There are currently no active channels enabled. Please click the Add ILS button above to enable available channels.</p>  
                :
                <div class="r-mt-6">     
                    <raul-grid>
                        <raul-grid-header>
                            <raul-grid-row>
                                <raul-grid-cell class="r-w-auto">
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/3">
                                    Channel
                                    <raul-grid-sorter onClick={() => channelSort("name")}></raul-grid-sorter>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/2">
                                    Listing Email Contact
                                    <raul-tooltip class="r-text r-cursor-pointer" text="The email address syndicated to the Channel for prospects to contact the property." placement="top">
                                        <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                    </raul-tooltip>
                                    <raul-grid-sorter onClick={() => channelSort("emailAddress")}></raul-grid-sorter>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/3">
                                    Phone Contact
                                    <raul-tooltip class="r-text r-cursor-pointer" text="The phone number syndicated to the Channel for prospects to contact the property." placement="top">
                                        <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                    </raul-tooltip>
                                    <raul-grid-sorter onClick={() => channelSort("phoneNumber")}></raul-grid-sorter>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/3">
                                    Listing Link
                                    <raul-tooltip class="r-text r-cursor-pointer" text="Listing Link provides you with a link to your property page for a particular channel. Links are not updated automatically.<br/><br/> To modify a link, simply select the pencil icon." placement="top">
                                        <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                    </raul-tooltip>
                                    <raul-grid-sorter onClick={() => channelSort("listingLink")}></raul-grid-sorter>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/3">
                                    Pick-Up Schedule
                                    <raul-tooltip class="r-text r-cursor-pointer" text="Select the times in this column to see all associated pickup times for each channel." placement="top">
                                        <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                                    </raul-tooltip>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/3">
                                    Unit Level
                                    <raul-grid-sorter onClick={() => channelSort("syndicatedUnitLevel")}></raul-grid-sorter>
                                </raul-grid-cell>
                                <raul-grid-cell class="r-w-1/6 r-justify-end"></raul-grid-cell>
                            </raul-grid-row>
                        </raul-grid-header>
                        <raul-grid-body>
                            { channelsList && channelsList.map((channel,index) => 
                                <raul-grid-row key={index}>
                                    <raul-grid-cell class="r-w-auto">
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/3 r-break-all">
                                        <div class="r-text-base">{channel.name}</div>
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/2 r-break-all">
                                        <div class="r-text-base">{channel.emailAddress}</div>
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/3 r-break-all">
                                        <div class="r-text-base">{channel.phoneNumber}</div>
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/3 r-break-all">
                                        <div class="r-text-base r-text-primary r-cursor-pointer">
                                            <a target='_blank' onClick={() => openListingLink(channel.listingLink)}>{channel.listingLink}</a>
                                        </div>
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/3 r-break-all">
                                        <div class="r-text-base r-text-primary r-cursor-pointer" onClick={() => pickupTimes(channel)}><raul-icon icon="clock" class="r-icon-md r-m-1"></raul-icon> View Times</div>
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/3 r-break-all">
                                    { channel. syndicatedUnitLevel === 'false' ?
                                        <div class="r-text-left r-pl-10 raul-status">
                                            <div class="r-status r-status--destructive">
                                                <div title="No">No</div>
                                            </div>
                                        </div> :
                                        <div class="r-text-left r-pl-10 raul-status">
                                            <div class="r-status r-status--success">
                                                <div title="Yes">Yes</div>
                                            </div>
                                        </div>
                                    }   
                                    </raul-grid-cell>
                                    <raul-grid-cell class="r-w-1/6 r-break-all r-justify-end">
                                        <div class="r-flex channel-action-items">
                                            <raul-icon icon="pencil" kind="consumer" class="aside-show-button r-cursor-pointer r-mr-3 r-ml-1 r-pt-1 r-icon-2xl" onClick={() => editChannel(channel)}></raul-icon>
                                            <raul-icon icon="bin" class="aside-show-button r-cursor-pointer r-text-destructive r-mr-3 r-ml-1 r-pt-1 r-icon-2xl" onClick={() => deleteChannel(channel)}></raul-icon>
                                            {props.countryCode?.trim() === 'UK' &&
                                            <raul-popover placement="left">
                                                <raul-popover-container slot="popover">                              
                                                    <raul-popover-content slot="popover-content" class="r-m-auto r-pt-3 r-pl-3 r-pr-0 r-mr-2 r-pb-4">
                                                        <ul class="r-ml-2"> 
                                                            <li class="r-text-left r-mr-3"><span class="r-font-bold">Branch ID : </span>{channel.branchId}</li>
                                                            <li class="r-text-left r-mr-3 r-mt-2"><span class="r-font-bold">Network ID : </span>{channel.networkId}</li>
                                                        </ul>   
                                                    </raul-popover-content>
                                                </raul-popover-container>
                                                <div className="r-cursor-pointer"><raul-icon icon="navigation-show-more-vertical" class="r-icon-lg"></raul-icon></div>
                                            </raul-popover>
                                            }
                                        </div>
                                    </raul-grid-cell>
                                </raul-grid-row>
                            )}
                        </raul-grid-body>
                    </raul-grid>
                </div>
            }
            <raul-paging-bar total-rows={props.channelList.length} class="r-font-medium" ref={channelPageRef} style={{visibility: channelsList && channelsList?.length == 0 ? 'hidden' : 'visible'}}></raul-paging-bar>
            </>

            <raul-aside id="lead-channel-aside" size="small" ref={leadChannelRef}>
                <raul-aside-header>
                    <raul-aside-title class="r-font-bold">
                        <div class="r-w-full">
                            <p class="r-mb-1 r-text-sm">{props.companyName}</p>
                            <p class="r-text-black">Edit Channel Info</p>
                        </div>
                    </raul-aside-title>
                </raul-aside-header>
                <raul-aside-body>
                    {leadChannel && 
                    <div>
                        <div class="r-mb-5">  
                            <span class="r-heading-md">Channel:</span>
                            <span>{leadChannel?.name}</span>
                        </div>
                        <div class="r-mb-5">
                            <raul-input label="Listing Email Contact">
                                <input type="text" placeholder="Enter Listing Email" value={emailAddress} onChange={(e) => handleChange(e,"emailAddress")}/>
                            </raul-input>
                        </div>
                        <div class="r-mb-5">
                            <raul-input label="Property Phone Contact">
                                <input type="text" placeholder="Enter Property Phone" value={phoneNumber}  onChange={(e) => handleChange(e,"phoneNumber")}/>
                            </raul-input>
                        </div>
                        <div class="r-mb-5">
                            <raul-input label="Listing Link">
                                <input type="text" placeholder="Enter Listing Link" value={listingLink}  onChange={(e) => handleChange(e,"listingLink")}/>
                            </raul-input>
                        </div>
                        { props.countryCode?.trim() === 'UK' &&
                        <div>
                            <div class="r-mb-5">
                                <raul-input label="Branch Id">
                                    <input type="text" placeholder="Enter Branch Id" value={branchId}  onChange={(e) => handleChange(e,"branchId")}/>
                                </raul-input>
                            </div>
                        
                            <div class="r-mb-5">
                                <raul-input label="NetWork Id">
                                    <input type="text" placeholder="Enter Network Id" value={networkId}  onChange={(e) => handleChange(e,"networkId")}/>
                                </raul-input>
                            </div>
                        </div>
                        }
                        
                    </div>
                    }
                </raul-aside-body>
                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button variant="secondary" id="lead-channel-close" onClick={() => cancelEdit()} >
                        Close
                        </raul-button>
                        <raul-button class="r-ml-2" variant="primary" id="lead-channel-save" onClick={() => saveEdit()}>
                        Save
                        </raul-button>
                    </raul-aside-actions>
                </raul-aside-footer>
            </raul-aside> 

            <raul-aside id="add-new-ils-aside" size="small" ref={addNewIlsRef}>
                <raul-aside-header>
                    <raul-aside-title>
                        <div class="r-w-full">
                            <p class="r-mb-1 r-text-sm">{props.property.propertyName}</p>
                            <p class="r-text-black">Add New ILS Channel(s)</p>
                        </div>
                    </raul-aside-title>
                </raul-aside-header>
                <raul-aside-body>
                <raul-input type="search" class="r-mb-3">
                        <input type="text" ref = {filterNewIlsRef} placeholder="Search for a Channel" onChange={filterIlsChannelChange}/>
                    </raul-input>
                
                { unAssignedLeadChannels && unAssignedLeadChannels.map((unAssignedLeadChannel, index) => 
                <div key={index}>
                    <raul-checkbox label-text={unAssignedLeadChannel.name} class="r-mb-4">
                        <input type="checkbox" name='ilsCheckBox' onClick={(event) => addNewIlsChannelCheck(event, unAssignedLeadChannel)} />
                    </raul-checkbox>
                </div>
            )}
            </raul-aside-body>
                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button variant="secondary" id="add-new-ils-close" onClick={() => cancelAddNewIls()} >
                        Close
                        </raul-button>
                        <raul-button class="r-ml-2" variant="primary" disabled={newIlsSelected}  id="add-new-ils-save" onClick={() => saveNewIls()}>
                        Save
                        </raul-button>
                    </raul-aside-actions>
                </raul-aside-footer>
            </raul-aside> 

            <raul-modal id="modal-with-two-buttons-example" ref={leadChannelDelRef}>
                <raul-modal-header>
                    <p class="r-font-semibold r-heading-lg r-text-center">Delete {leadChannel?.name} Channel </p></raul-modal-header>

                <raul-modal-body>
                    The channel and its configurations will be removed from this property.
                </raul-modal-body>

                <raul-modal-footer>
                    <raul-button size="small" id="close-modal-with-two-buttons-button" onClick={() => cancelDelete(leadChannel)}>Cancel</raul-button>

                    <raul-button size="small" variant="danger" onClick={() => confirmDelete(leadChannel)}>Delete Channel</raul-button>
                </raul-modal-footer>
            </raul-modal>

            <raul-aside id="pickup-times-aside" size="small" ref={pickUpTimes}>
                <raul-aside-header>
                    <raul-aside-sub-title class="r-font-semibold r-heading-md">{leadChannel?.name}</raul-aside-sub-title>
                    <raul-aside-title><span class="r-heading-lg">Pickup Times</span></raul-aside-title>
                </raul-aside-header>
                <raul-aside-body>
                    <div class="r-pb-6">
                        <p class="r-font-bold r-pb-2">Last Pickup Time</p>
                        <ul class="r-pl-6 r-list-disc">
                        { pickUpTimesRes.subChannelDetails && pickUpTimesRes.subChannelDetails.map((subChannel,index) => 
                            <li class="r-pb-2" key={index}>
                                <span>{subChannel.name}</span> 
                                <span class="r-p-1">--</span>
                                <span>{subChannel.lastSent}</span>
                            </li>
                        )}
                        </ul>
                    </div>
                    <div class="r-pb-6">
                        <p class="r-font-bold r-pb-2">Next Pickup Time</p>
                        <ul class="r-pl-6 r-list-disc">
                            <li>
                                <span class="r-p-1">{pickUpTimesRes.nextPickUpTime}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="r-pb-6">
                        <p class="r-font-bold r-pb-2">All Pickup Times</p>
                        <ul class="r-pl-6 r-list-disc three-column-list">
                        { pickUpTimesRes.pickupTimes && pickUpTimesRes.pickupTimes.map((time,index) => 
                            <li key={index}>
                                <span class="r-p-1">{time}</span> 
                            </li>
                        )}
                        </ul>
                    </div>
                </raul-aside-body>
                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button variant="secondary" id="pickup-times-close" onClick={() => cancelpickUpTimes()} >
                        Close
                        </raul-button>
                    </raul-aside-actions>
                </raul-aside-footer>
            </raul-aside> 
        </>
    );
};

export default Channels;
